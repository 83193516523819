$primary: #ff6961;
$primary-hover: #ea4c4c;
$black: #2e3333;
$gray: #6b6a6a;

$input-bg: #ededed;
$input-text: #333333;

$warning-red: #ef5350;
$success-green: #27ae60;
