@import "styles/colors";

.testimonials-wrapper {
  background-color: #fceee1;

  .container {
    background-color: #f9f9f9;
    padding: 2.5rem 2rem 3.5rem;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    .main-heading {
      text-align: center;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    .main-sub-heading {
      text-align: center;
      max-width: 80%;
      margin: 0 auto;
    }

    .testimonials-slider {
      margin-top: 2rem;
    }

    .testimonials-slider {
      .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
      }

      .slick-initialized .slick-slide {
        display: block;
      }

      .testimonials-item {
        margin-top: 1rem;
        margin-bottom: 2rem;
      }

      .center .testimonials-item-inner {
        opacity: 0.8;
        transition: all 0.3s ease;
        -ms-transform: scale(0.95);
        transform: scale(0.95);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }

      .center .slick-center .testimonials-item-inner {
        opacity: 1;
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }

      .testimonials-item-inner {
        margin: 16px;
        position: relative;
        text-align: center;
        background-color: #ffffff;
        border-radius: 16px;
        overflow: hidden;

        .testimonial-msg {
          text-align: left;
          padding: 1.5rem;
          color: $gray;
        }

        .testimonial-person {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1.5rem;
          background-color: #fafbff;

          .t-person-img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .t-person-details {
            text-align: left;

            .typography--variant-baseMedium {
              color: $black;
              margin-bottom: 4px;
            }

            .typography--variant-extraSmallRegular {
              color: $primary;
            }
          }
        }
      }

      .slick-prev {
        left: 3% !important;
        z-index: 1;
        top: 103%;
      }

      .slick-next {
        right: 3% !important;
        z-index: 1;
        top: 103%;
      }

      .slick-prev:before,
      .slick-next:before {
        color: #9d9d9d;
        font-size: 25px;
      }
    }
  }
}
