@import "styles/colors";

.hero-wrapper {
  display: grid;
  grid-template-columns: 40% calc(60% - 3rem);
  grid-template-rows: auto;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 3rem;

  .hero-details {
    .main-heading {
      span {
        display: block;
        color: #858585;
      }
    }
    .main-sub-heading {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .hero-image {
    img {
      width: 100%;
    }
  }
}
