@import "styles/colors";

.easy-to-use-wrapper {
  box-shadow: 0 10px 14px rgb(0 0 0 / 10%);
  background-image: linear-gradient(to bottom, white, transparent),
    linear-gradient(to right, white 50%, #f4f5f7 50%);
  background-repeat: no-repeat;

  .main-heading {
    text-align: center;
  }

  .main-sub-heading {
    text-align: center;
    max-width: 80%;
    margin: 1rem auto;
  }

  .easy-connection {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    background-image: linear-gradient(to right, #ffdaea, #dceeff);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 53% 5px;
    padding-left: 22%;
    padding-right: 22%;

    .easy-product-item {
      &--store-front {
        background-color: #ffeaf3;
        padding: 18px;
        border-radius: 20px;

        img {
          width: 50px;
        }
      }

      &--dashboard {
        background-color: #e9f3fc;
        padding: 18px;
        border-radius: 20px;

        img {
          width: 50px;
        }
      }
    }
  }

  .easy-sec-contents {
    .storefront-sub-title {
      color: $gray;
    }
  }

  .easy-sec-contents {
    display: flex;
    margin-top: 1rem;
  }

  .easy-sec-storefront,
  .easy-sec-dashboard {
    width: 50%;
    padding: 16px;

    .easy-sec-header {
      text-align: center;

      h3 {
        margin-bottom: 6px;
      }
    }

    .easy-sec-content-wrapper {
      margin-top: 2rem;

      .easy-sec-content-item {
        display: flex;
        align-items: center;
        max-width: 90%;
        margin: 1rem auto;
        overflow: hidden;
        border-radius: 16px;
        background-color: #182332;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

        .es-icon {
          width: 30%;
          height: auto;
          text-align: center;

          img {
            width: 50px;
          }
        }

        .es-details {
          padding: 24px;
          width: 70%;
          background-color: #1b2636;

          .typography--variant-h4 {
            margin-bottom: 12px;
            color: #ffffff;
          }

          .typography--variant-baseRegular {
            color: #cfcfcf;
          }
        }
      }
    }
  }
}
