.discover-features-wrapper {
  background-color: #f5f5f7;

  .main-heading {
    text-align: center;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .discover-features-main-wrapper {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "heading media"
      "questions media";
    gap: min(5vw, 40px) 3em;
    margin-top: 3rem;

    .content-sidebar {
      .df-main-heading {
        font-size: 3.5rem;
        font-weight: 700;
        word-break: break-word;
        padding-top: 20%;

        em {
          display: inline;
          font-style: normal !important;
          font-weight: inherit !important;
          background-image: linear-gradient(
            90deg,
            #e91e63,
            #ff6961 33%,
            #3f51b5
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }

      .main-sub-heading {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      .df-info-content {
        margin-bottom: 5rem;
        background-color: #d9edf7;
        border-radius: 12px;
        padding: 16px 24px;
        display: flex;
        flex-direction: column;
        margin-top: 2em;
        gap: 1.5em;
        align-items: flex-start;

        .df-info-item {
          .typography--variant-h5 {
            margin-bottom: 0.15em;
          }
        }
      }

      .feature-request {
        margin-top: 3rem;
      }
    }

    .content-image {
      border-radius: 18px;
      overflow: hidden;
      border: 12px solid #f7f7f7;
      background-color: #f0f0f0;
      box-shadow: 0 0 0 1px rgb(0 0 0 / 6%), 0 9.5px 12px -3px rgb(0 0 0 / 8%),
        0 19.5px 30.5px 0 rgb(0 0 0 / 3%), 0 7px 40px 0 rgb(0 0 0 / 2%);

      img {
        width: 100%;
      }
    }
  }

  .df-dashboard {
    .df-main-heading {
      padding-top: 10% !important;
    }
  }
}
