@import "styles/colors";

.about-wrapper {
  background-color: #f7f9fd;

  .main-heading {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
  }

  .main-sub-heading {
    text-align: center;
    max-width: 80%;
    margin: 3rem auto;
  }

  .values-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    .value-item {
      width: 24%;
      background-color: #ffffff;
      border-radius: 16px;
      padding: 1.5rem;
      box-shadow: #d8def7 0px 20px 25px -5px, #d8def7 0px 10px 10px -5px;

      &:nth-child(1) .value-icon {
        background-color: #ddfadd;
        border-radius: 20px;
      }

      &:nth-child(2) .value-icon {
        background-color: #e9f3fc;
        border-radius: 8px;
      }

      &:nth-child(3) .value-icon {
        background-color: #ddf9fa;
        border-radius: 15px;
      }

      &:nth-child(4) .value-icon {
        background-color: #ffeaf3;
        border-radius: 50%;
      }

      .typography--variant-h4 {
        margin-bottom: 6px;
      }

      .typography--variant-baseRegular {
        color: $gray;
      }

      .value-icon {
        height: 60px;
        margin-bottom: 12px;
        display: inline-block;
        padding: 10px;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
