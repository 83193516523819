.feature-screen {
  text-align: center;
  margin-bottom: 2rem;

  img {
    max-width: 900px;
  }
}

.features-container {
  background-color: #232429;
  padding: 3rem;
  border-radius: 16px;

  .features-wrapper {
    padding: 0 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    row-gap: 2.5rem;

    .features-item {
      width: 31.3333%;
      gap: 1%;
      padding: 2rem;
      border-radius: 16px;
      background: rgb(2, 0, 36);
      background: linear-gradient(321deg, #24252a 15%, #2e2f31 100%);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;

      img {
        width: 50px;
        margin-bottom: 1.5rem;
      }

      .typography--variant-h3 {
        color: #ffffff;
      }

      .typography--variant-baseRegular {
        color: #cfcfcf;
      }
    }
  }
}
