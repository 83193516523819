@import "styles/colors";

.get-ready-wrapper {
  background-image: linear-gradient(
    180deg,
    rgba(255, 105, 97, 0.25) 1%,
    rgba(187, 187, 187, 0) 70.9%
  );

  .main-heading {
    text-align: center;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .main-sub-heading {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
  }

  .get-ready-steps-wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.25rem;
    grid-row-gap: 0px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 3rem;

    .get-ready-step {
      text-align: center;
      padding: 2rem;

      .get-ready-image {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;

        img {
          width: 100%;
        }
      }

      .get-ready-content {
        .typography--variant-h3 {
          font-weight: 700;
        }

        .typography--variant-baseRegular {
          color: $gray;
        }
      }
    }
  }
}
