@import "styles/colors";

.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  color: #000000;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.main-container {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  flex-grow: 1;

  .hytch-logo {
    display: inline-block;

    img {
      max-width: 200px;
    }
  }
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  padding: 0;
}

.nav-links {
  color: #000000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-size: 20px;
}

.nav-sign-up {
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 16px;
  background-color: $primary;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  font-weight: 600;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: $primary-hover;
  }
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-left: 1rem;
}

.nav-item:after:not(.nav-sign-up) {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: $primary;
  border-radius: 4px;
}

.nav-item.active {
  color: $primary;
  border: 1px solid $primary;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 92px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    margin: 0;
    height: calc(100vh - 105px);
    padding: 1rem;
    border-radius: 4px;
  }
  .nav-menu.active {
    background: #ffffff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;

    .nav-item {
      margin-left: 0;
    }
  }
  .nav-item .active {
    color: $primary;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
    margin: 0;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 2px;
    right: -30px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: $primary;
    background-color: #ffffff;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
