.section-common {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &--2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &--double {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &--LR {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
