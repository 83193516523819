@import "styles/colors";

.integrations-wrapper {
  background-image: linear-gradient(
    359.3deg,
    rgb(255 105 97 / 25%) 1%,
    rgba(187, 187, 187, 0) 70.9%
  );

  .main-heading {
    text-align: center;
  }
  .main-sub-heading {
    text-align: center;
  }

  .integrations-inner {
    display: flex;
    align-items: center;
    margin-top: 4rem;

    .integrations-image {
      width: 40%;

      img {
        width: 100%;
      }
    }

    .integrations-details {
      width: 60%;
      padding-left: 3rem;

      .integrations-about {
        line-height: 1.5;
      }

      .integrations-desc {
        color: $gray;
      }

      .integrated-items {
        margin-top: 2rem;

        .integrated-item-icon {
          background-color: rgb(255 105 97 / 25%);
          border-radius: 10px;
          padding: 10px;

          svg {
            color: $primary;
            font-size: 28px;
            vertical-align: middle;
          }
        }

        .integrated-item {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;

          .integrated-item-details {
            .typography--variant-h5 {
              margin: 0;
              font-weight: 700;
              color: #222222;
            }
          }
        }
      }
    }
  }
}
